import React from 'react'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import H2 from '../../elements/typography/h2'
import SectionHeader from '../../components/sectionHeader'
import Grid from '../../elements/grid'
import SectionTransition from '../../elements/activeSectionDefault'
import { Link } from 'gatsby'
import { breakpoint, getColor } from '../../shared/theme'

const FeaturedWrapper = styled(SectionTransition)`
	background: ${getColor('black')};

	@media ${breakpoint('tablet')} {
		background: transparent;
	}
`

const FeaturedBlock = styled.div`
	padding: 48px 0 80px;
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		padding: 132px 120px 168px;
		background: ${getColor('black')};
	}

	@media ${breakpoint('laptop')} {
		grid-column: 2 / 14;
	}

	@media ${breakpoint('desktop')} {
		grid-column: 3 / 13;
	}
`

const Heading = styled(H2)`
	display: none;
	color: ${getColor('white')};
	text-transform: uppercase;
	font-weight: 800;
	text-align: center;
	font-size: 44px;
	margin-bottom: 80px;

	@media ${breakpoint('tablet')} {
		display: block;
	}
`

const HeaderMob = styled(SectionHeader)`
	margin-bottom: 48px;
	text-transform: uppercase;
	letter-spacing: 0;
	color: ${getColor('white')};

	h6 {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 30px;
			height: 1px;
			background-color: ${getColor('white')};
		}
	}

	@media ${breakpoint('tablet')} {
		display: none;
	}
`

const LogoBlock = styled.div``

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;

	&:last-child {
		margin-bottom: 0;
	}

	@media ${breakpoint('tablet')} {
		margin-bottom: 54px;
	}
`

const LogoWrapper = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: calc(33.33% - (40px * 2 / 3));

	@media ${breakpoint('tablet')} {
		width: 100%;
	}
`

const Logo = styled.img`
	max-height: 40px;
	max-width: 100%;
	object-fit: contain;

	@media ${breakpoint('tablet')} {
		max-width: 200px;
		max-height: 60px;
	}

	@media ${breakpoint('desktop')} {
		max-width: 250px;
	}
`

const FeaturedPress = ({ featuredPressLogos, isActive }) => {
	const chunk = (arr, size) =>
		Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
			arr.slice(i * size, i * size + size),
		)

	const logoRows = chunk(featuredPressLogos, 3)

	if (featuredPressLogos == null || featuredPressLogos.length <= 0) {
		return null
	}

	return (
		<FeaturedWrapper isActive={isActive} classname="with-background module">
			<InnerWrapper>
				<Grid>
					<FeaturedBlock>
						<Heading>Featured In:</Heading>
						<HeaderMob as="div">
							<h6>Featured In</h6>
						</HeaderMob>
						<LogoBlock>
							{logoRows.map((row, index) => (
								<Row key={index}>
									{row.map((logo, index) => (
										<LogoWrapper key={index}>
											<Link
												to={logo.link.url}
												title={logo.link.title}
												target="blank"
											>
												<Logo src={logo.image.mediaItemUrl} />
											</Link>
										</LogoWrapper>
									))}
								</Row>
							))}
						</LogoBlock>
					</FeaturedBlock>
				</Grid>
			</InnerWrapper>
		</FeaturedWrapper>
	)
}

export default FeaturedPress
