import React from 'react'
import styled from 'styled-components'
import Grid from '../../elements/grid'
import TextBlock from '../../components/textBlock'
import InnerWrapper from '../../elements/innerWrapper'
import { breakpoint, getColor } from '../../shared/theme'
import SectionHeader from '../../components/sectionHeader'
import SectionTransition from '../../elements/activeSectionDefault'

const LinkGrid = styled(SectionTransition)`
	padding: 48px 0 100px;
	background: ${getColor('greyLight')};
	margin-bottom: 60px;

	@media ${breakpoint('tablet')} {
		padding: 80px 0 140px;
		margin-bottom: ${(props) => (props.spacing == 'large' ? '200px' : '136px')};
	}

	@media ${breakpoint('desktop')} {
		padding: 92px 0 180px;
	}
`

const BlockWrapper = styled.div`
	grid-column: -1 / 1;
	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	column-gap: 16px;
	row-gap: 20px;

	@media ${breakpoint('tablet')} {
		grid-column: 2 / 14;
		column-gap: 40px;
		row-gap: 54px;
	}

	@media ${breakpoint('desktop')} {
		grid-column: 3 / 13;
	}
`

const ExternalLinks = ({ sectionTitle, links, isActive }) => {
	return (
		<LinkGrid className="with-background module" isActive={isActive}>
			<InnerWrapper>
				<SectionHeader title={sectionTitle} />
				<Grid>
					{links && (
						<BlockWrapper>
							{links.map((link, index) => (
								<TextBlock data={link} key={index} />
							))}
						</BlockWrapper>
					)}
				</Grid>
			</InnerWrapper>
		</LinkGrid>
	)
}

export default ExternalLinks
