import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import H4 from '../../elements/typography/h4'
import P from '../../elements/typography/p'
import { breakpoint, getColor } from '../../shared/theme'

const Block = styled.div`
	grid-column: -1 / 1;
	padding-bottom: 24px;
	border-bottom: 1px solid ${getColor('black')};

	&:last-child() {
		margin-bottom: 0;
	}

	@media ${breakpoint('mobile')} {
		grid-column: span 6;
		padding-bottom: 62px;
	}
`

const Heading = styled(H4)`
	margin-bottom: 15px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 32px;
	}
`

const Excerpt = styled.div`
	padding-right: 25%;
	margin-bottom: 48px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 24px;
	}
`

const Link = styled.a`
	text-transform: uppercase;
	text-decoration: underline;
	font-size: 10px;
	font-weight: 800;
`

const TextBlock = ({ data }) => {
	return (
		<Block>
			<Heading>{data.title}</Heading>
			<Excerpt dangerouslySetInnerHTML={{ __html: data.excerpt }} />
			<Link href={data.link} target="_blank">Read more</Link>
		</Block>
	)
}

TextBlock.defaultProps = {
	data: {},
}

TextBlock.propTypes = {
	data: PropTypes.object,
}

export default TextBlock
