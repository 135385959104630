import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '../../elements/grid'
import InnerWrapper from '../../elements/innerWrapper'
import SectionTransition from '../../elements/activeSectionDefault'
import { breakpoint } from '../../shared/theme'
import Quote from '../../components/quote'

const BlockquoteWrapper = styled(SectionTransition)`
	padding: 72px 0 54px;

	@media ${breakpoint('tablet')} {
		padding: ${(props) => (props.spacing == 'large' ? '200px 0' : '136px 0')};
	}
`

const QuoteWrapper = styled.div`
	grid-column: -1 / 1;
	position: relative;

	@media ${breakpoint('tablet')} {
		grid-column: 2 / 14;
	}

	@media ${breakpoint('desktop')} {
		grid-column: 3 / 13;
	}
`

const Blockquote = ({ copy, source, isActive }) => {
	return (
		<BlockquoteWrapper
			spacing={'large'}
			isActive={isActive}
			className="blockquote module"
		>
			<InnerWrapper>
				<Grid>
					<QuoteWrapper>
						<Quote copy={copy} source={source} />
					</QuoteWrapper>
				</Grid>
			</InnerWrapper>
		</BlockquoteWrapper>
	)
}

Blockquote.defaultProps = {
	copy: '',
	source: '',
	isActive: true,
}

Blockquote.propTypes = {
	copy: PropTypes.string,
	source: PropTypes.string,
	isActive: PropTypes.bool,
}

export default Blockquote
