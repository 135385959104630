import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowIcon from '../../images/blockquote-arrow.svg'
import QuoteIcon from '../../images/quotemark.svg'
import { breakpoint, getColor } from '../../shared/theme'

const Copy = styled.blockquote`
	font-size: 20px;
	line-height: 1.63;
	letter-spacing: -1px;
	font-weight: 800;
	margin-bottom: 40px;
	display: block;
	position: relative;

	&::before {
		content: '';
		background-image: url(${QuoteIcon});
		background-size: 28px 24px;
		width: 28px;
		height: 24px;
		position: absolute;
		top: -34px;
		left: 0;
	}

	@media ${breakpoint('tablet')} {
		font-size: 38px;
		line-height: 1.27;
		text-indent: 65px;
		margin-bottom: 95px;

		&::after {
			content: '';
			position: absolute;
			background-image: url(${ArrowIcon});
			background-repeat: no-repeat;
			background-size: contain;
			bottom: 0;
			left: -40px;
			width: 9px;
			height: 75%;
			min-height: 48px;
			max-height: 275px;
			transform: translate(-100%, -0.25em);
		}

		&::before {
			top: 12px;
			width: 39px;
			height: 32px;
			background-size: 39px 32px;
		}
	}

	@media ${breakpoint('desktop')} {
		font-size: 48px;
	}
`

const Source = styled.div`
	font-size: 10px;
	font-weight: 800;
	text-transform: uppercase;
	position: relative;

	&::before {
		content: '';
		height: 2px;
		width: 8px;
		position: absolute;
		left: 0;
		top: -10px;
		background-color: ${(props) => props.theme.colours.black};
	}

	@media ${breakpoint('mobile')} {
		font-size: 14px;

		&::before {
			top: -26px;
		}
	}
`

const Quote = ({ copy, source }) => {
	return (
		<>
			<Copy>{copy}</Copy>
			<Source>{source}</Source>
		</>
	)
}

Quote.defaultProps = {
	copy: '',
	source: '',
}

Quote.propTypes = {
	copy: PropTypes.string,
	source: PropTypes.string,
}

export default Quote
